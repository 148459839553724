<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <div class="d-flex flex-row">
         <SideBarProfile></SideBarProfile>
          <div class="flex-row-fluid ml-lg-8">
            <div class="card card-custom card-stretch">
              <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">
                    {{$t('profile.user_information')}}
                  </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">{{$t("profile.user_information_update")}}</span>
                </div>
                <div class="card-toolbar">
                  <button   :disabled="saveValidation" @click="UpdateProfile"  type="reset" class="btn btn-success mr-2">{{$t('general.save')}}</button>
                  <button @click="$router.push({name: 'profile.edit'})"  type="reset" class="btn btn-secondary">{{ $t("general.cancel") }}</button>
                </div>
              </div>
                <div class="card-body">
                  <img :src="currentUser.avatar"  id="profile-image-holder"   style="height: 50px; ">

                  <file-input-change
                      :is-inline="true"
                      :title="$t('profile.user_photo')"
                      name="user_photo"
                      :required="false"
                      :model.sync="profileImage"
                      @fileChange="onProfileImageChange"
                      disabled="f"
                  >
                  </file-input-change>
                  <text-input
                      is-inline="true"
                      :title="`${$t('profile.user_name')}`"
                      :model.sync="currentUser.name"
                      :is-valid=" currentUser.name != null && currentUser.name.length !== 0 ? true : null"
                      :error=" currentUser.name != null && currentUser.name.length === 0 ? $t('general.this_field_cant_be_empty') : null"
                  ></text-input>

                  <text-input
                      is-inline="true"
                      :title="`${$t('profile.phone_number')}`"
                      :model.sync="currentUser.phone_number"
                      :is-valid="phoneNumberValidate"
                      :error="currentUser.phone_number != null && currentUser.phone_number.length === 0 ? $t('general.this_field_cant_be_empty') : null"
                  ></text-input>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardBox from "@/assets/components/DashboardBox";
import $ from 'jquery'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import TextInput from "@/assets/components/inputs/TextInput";
import FileInput from "@/assets/components/inputs/FileInput";
import SideBarProfile from "@/assets/components/profile/SideBarProfile";
import FileInputChange from "@/assets/components/inputs/FileInputChange";
import {CREATE_ITEM, LOADING, ERROR, SUCCESS,} from "@/core/services/store/REST.module";
export default {
  name: "ProfileEdit",
  components: {
    TextInput,
    DashboardBox,
    FileInput,
    SideBarProfile,
    FileInputChange
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
    }),
    phoneNumberValidate() {
      return (/^([0-9]{10})$/.test(this.currentUser.phone_number));
    },
  },
  data() {
    return {
      id: null,
      profileImage: null,
      currentUser: {
        name:null,
        avatar:null,
        phone_number:null,
      },
    };
  },
  methods: {
    ...mapMutations({
    }),
    getData(){
      if(this.authUser){
      if(this.authUser){
        this.currentUser.name= this.authUser.name
        this.currentUser.phone_number= this.authUser.phone_number
      }
      if(this.authUser.avatar){
        this.currentUser.avatar=this.authUser.avatar
      };
    }
    },
    onProfileImageChange(payload){
      let imageHolder = $('#profile-image-holder');
      imageHolder.attr('src', payload.blob);
    },
    UpdateProfile(){
      let form = new FormData(), self = this;
      form.append('name', this.currentUser.name);
      form.append('phone_number', this.currentUser.phone_number);

      if(this.profileImage !== null){
        form.append('avatar', this.profileImage);
      }

      self.sweetAlertLoading();
      let payload = {
        url:'api/user/profile',
        id: this.id,
        contents: form,
        acceptPromise: true,
        showLoading: false
      }
      this.$store.dispatch(CREATE_ITEM, payload).then((result) => {
        self.sweetAlertSuccess(self.$t('general.successfully_updated'));
      });
    },
  },
  mounted() {
    this.getData();
    this.id = this.$route.params.id;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("profile.user_profile"), route: "profile.edit" },
    ]);
  },
  watch:{
    authUser(){
      this.getData();
    }
  }
}
</script>
<style scoped>
</style>
